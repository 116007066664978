.calendar-heading {
  .react-datepicker-popper {
    z-index: 9;
  }
  &-controls {
    &-button {
      background-color: #2c3e50;
      color: white;
      padding: 0.4rem 0.65rem;

      &:hover {
        background-color: #1e2b37;
      }

      &--date-active {
        background-color: #737f8b;
        &:hover {
          background-color: #737f8b;
        }
      }

      &--mode-active {
        background-color: #1a252f;
        &:hover {
          background-color: #1a252f;
        }
      }
    }
  }

  .horizontal-selected-date-range {
    background-color: #0050ff;
    color: white !important;
    &:hover {
      background-color: #0050ff;
    }
    div {
      color: white !important;
    }
  }

  // * Horizontal calendar button
  .Kq {
    display: none;
  }
}
