@mixin pagination-button {
  display: inline-block;
  padding: 6px;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #313131;
  user-select: none;

  &:hover {
    background-color: #004c7e3b;
  }
}

@mixin itemCenter {
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;

  &-container {
    @extend .pagination;
  }

  &-left,
  &-right {
    @include pagination-button;
    @include itemCenter;
    &-disabled {
      @include pagination-button;
      @include itemCenter;
      border-color: #afadad;
      background-color: #ebebeb;
      color: #afadad;
      pointer-events: none;
    }
  }

  &-numbers {
    @include pagination-button;
    @include itemCenter;
    text-align: center;
    align-items: center;
    justify-content: center;
    &-active {
      @extend .pagination-numbers;
      border-color: #004b7e;
      background-color: #004b7e;
      color: #fff;

      &:hover {
        background-color: #004b7e;
      }
    }
  }
}
