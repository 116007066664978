.single-pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
}

.single-pdf {
  border-radius: 6px;
  transform: scale(0.3);
}

.multiple-pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
}

.multiple-pdf {
  border-radius: 6px;
  //   transform: scale(0.3);
}
