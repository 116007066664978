@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim.eot');
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim.woff2') format('woff2'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim.woff') format('woff'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Bold.eot');
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Bold.woff2') format('woff2'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Bold.woff') format('woff'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Medium.eot');
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Medium.woff2') format('woff2'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Medium.woff') format('woff'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Light.eot');
  src: url('../../assets/fonts/GTWalsheim/GTWalsheim-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Light.woff2') format('woff2'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Light.woff') format('woff'),
    url('../../assets/fonts/GTWalsheim/GTWalsheim-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/fonts/Merriweather/Merriweather-Light.eot');
  src: url('../../assets/fonts/Merriweather/Merriweather-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Merriweather/Merriweather-Light.woff2')
      format('woff2'),
    url('../../assets/fonts/Merriweather/Merriweather-Light.woff')
      format('woff'),
    url('../../assets/fonts/Merriweather/Merriweather-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/fonts/Merriweather/Merriweather-Bold.eot');
  src: url('../../assets/fonts/Merriweather/Merriweather-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Merriweather/Merriweather-Bold.woff2')
      format('woff2'),
    url('../../assets/fonts/Merriweather/Merriweather-Bold.woff') format('woff'),
    url('../../assets/fonts/Merriweather/Merriweather-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/fonts/Merriweather/Merriweather-Black.eot');
  src: url('../../assets/fonts/Merriweather/Merriweather-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Merriweather/Merriweather-Black.woff2')
      format('woff2'),
    url('../../assets/fonts/Merriweather/Merriweather-Black.woff')
      format('woff'),
    url('../../assets/fonts/Merriweather/Merriweather-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/fonts/Merriweather/Merriweather-Regular.eot');
  src: url('../../assets/fonts/Merriweather/Merriweather-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Merriweather/Merriweather-Regular.woff2')
      format('woff2'),
    url('../../assets/fonts/Merriweather/Merriweather-Regular.woff')
      format('woff'),
    url('../../assets/fonts/Merriweather/Merriweather-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
