.variation-tab {
  width: 100%;
  display: flex;
  //   height: fit-content;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  cursor: pointer;

  & > .text-section {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      white-space: normal !important;
    }
  }
}
