.status {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
  }

  &-progress {
    &:before {
      background-color: $invoice;
    }
  }

  &-resolved {
    &::before {
      background-color: $notice;
    }
  }

  &-feedback {
    &::before {
      background-color: $quote;
    }
  }

  &-open {
    &::before {
      background-color: $worker_invoice;
    }
  }

  &-closed {
    &::before {
      background-color: $project;
    }
  }
  &-rejected {
    &::before {
      background-color: $ticket;
    }
  }
}
