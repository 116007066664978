//TOOLTIP COMMON
.tooltip-wrapper {
  position: relative;

  .tooltip-text {
    visibility: hidden;
    position: absolute;
    background-color: black;
    padding: 2px 8px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    z-index: 1;
    white-space: nowrap;
    align-self: center;
    transition: visibility 0.1s ease-in-out 0.1s;
    font-size: 12px !important;
  }
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
}
