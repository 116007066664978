.calendar-sidenav {
  &.open {
    max-width: 350px;
    // transition: all 300ms ease-in-out;
  }

  &.closed {
    width: 40px;
    // transition: all 300ms ease-in-out;
  }

  &-body {
    &-projects {
    }
  }
}
