#responsive-overlay-portal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    isolation: isolate;
    height: 100vh;
    width: 100%;
    pointer-events: none;
}
