$black: #000000;

// Grey variants
$grey100: #a1a1a1;
$grey200: #e1e1e1;
$grey300: #f1f1f1;
$grey400: #f8f8f8;

$notification-header-badge-color: #e50000;

$mark-all-read-color: #0036d0;

// $success: #62a302;
// $warning: #dd7d00;
// $info: #0074b8;
// $error: #e50000;

$notice: #76cc00;
$project: #ffee00;
$quote: #ffb500;
$invoice: #3452c0;
$worker_invoice: #8100cc;
$ticket: #e2420d;

$notif-sizes: (
  10: '10px',
  12: '12px',
  14: '14px',
  25: '20px',
);
