progress::-moz-progress-bar,
progress::-webkit-progress-bar,
progress,
progress[value]::-webkit-progress-bar,
progress[value] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  color: #76cc00;
  background: #76cc00;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress::-moz-progress-value,
progress::-webkit-progress-value,
progress,
progress[value]::-webkit-progress-value,
progress[value] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  color: #76cc00 !important;
  background: #76cc00 !important;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress-bar {
  background: #76cc00 !important;
}
