@import './components/components';

.calendar-page-main {
  // width: calc(100% - 350px);
  flex: 1;
}

.fc {
  .fc-col-header-cell-cushion {
    width: 100%;
    padding: 0;
    border: 0;
  }
}

.fc-theme-standard th {
  //   border: none !important;
}

// .fc-theme-standard td {
//   tr {
//     &:nth-child(odd) {
//       background-color: white;
//     }
//     &:nth-child(even) {
//       background-color: #dceef9;
//     }
//   }
// }

// * Resource tab
.fc-datagrid-cell-frame {
  user-select: none; // * Disable user from selecting the text
  // height: 100% !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: #dceef9;
  }
  .fc-datagrid-cell-cushion {
    width: 100%;
  }
}

.fc-datagrid-cell-frame:hover .fc-scrollgrid-sync-table {
  tr {
    background-color: #dceef9;
  }
}
.fc-timeline-lane-frame {
  // height: 101% !important;
}

.fc-timeline-events {
  &.fc-scrollgrid-sync-inner {
    // height: 100% !important;
  }
}
.fc-timeline-event-harness {
  // height: calc(100% - 10px);
}

.fc-timeline-slot-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ew-resize;
  user-select: none;
  .fc-timeline-slot-cushion {
    &.fc-scrollgrid-sync-inner {
      width: 100%;
    }
  }
}
.fc-timeline-header-row {
  user-select: none;
  -webkit-user-drag: none;
}
.fc-timeline-slots {
  table {
    tbody {
      tr {
        td {
          border: 0px;
          border-collapse: collapse;

          &.fc-timeline-slot-major {
            border: 1px solid #ddd;
            border-collapse: separate;
          }
        }
      }
    }
  }
}
