.rdt_Table {
  display: table !important;
  width: 100%;
}

.rdt_TableRow {
  display: table-row !important;
}

.rdt_TableCol {
  display: table-cell !important;
}

// .rdt_TableCol_Sortable {
//   display: table-cell !important;
// }

.rdt_TableCell {
  display: table-cell !important;

  &:first-child:has(input) {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.rdt_TableHeader {
  display: table-row-group !important;
}

.rdt_TableFooter {
  display: table-row-group !important;
}

.rdt_TableHead {
  display: table-header-group !important;
}
.rdt_TableHeadRow {
  display: table-row !important;
}

.rdt_TableBody {
  display: table-row-group !important;
}

.rdt_ExpanderRow {
  display: table-row !important;
}

div:has(> .rdt_ProgressComponent) {
  display: table-caption !important;
  caption-side: bottom;
}
