.recharts-tooltip-wrapper {
  isolation: isolate;
  z-index: 1000000000;
}

.overall-position-tooltip {
  padding: 16px;
  width: max-content;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  border: 1px solid white;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);

  font-weight: 700;
  letter-spacing: 0.8px;

  & > .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;

    color: #004b7e;

    & > .label-value {
      letter-spacing: 0.9px;
    }
  }

  & > .separator {
    background-color: white;
    height: 2px;
    width: 100%;
  }
}
