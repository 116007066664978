.leaflet-popup.draggable-actions {
  translate: 0% 150%;

  & > .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: transparent !important;
    font-size: 30px;
    box-shadow: none !important;
  }
}
