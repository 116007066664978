.grid-layout-item {
  .react-draggable-handle {
    color: transparent;
  }

  &:hover {
    .react-draggable-handle {
      color: #9e9e9e;
      background-color: rgb(255, 255, 255, 0.15);
      border-radius: 4px;
      backdrop-filter: blur(15px);
    }
  }
}
