@import './variables';

.notification-modal-holder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 999 !important;
  background-color: rgba($color: #000000, $alpha: 0.4);
}

.modal-box {
  min-height: 0;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: fit-content;
  padding: map-get($map: $notif-sizes, $key: 10);
  border-radius: map-get($map: $notif-sizes, $key: 10);
}

.header-holder {
  border: none;

  .notification-heading {
    .notification-title {
      height: fit-content;

      .title-text {
        align-items: center;
        column-gap: 1rem;
      }
      .badge {
        border: none;
        margin-left: 4px;
        padding: 5px 8px;
        background-color: $notification-header-badge-color;
        color: white;
        border-radius: 5px;
        width: max-content;
      }
    }

    .search-field {
      align-items: center;
      column-gap: map-get($map: $notif-sizes, $key: 14);

      .search {
        width: fit-content;
        border: 1px solid $grey200;
        background: $grey300;
        align-items: center;
        color: $grey100;
        cursor: text;
        .search-input {
          color: $black;
          width: 170px;
          background: transparent;
        }

        &:hover,
        &:focus-within {
          border: 1px solid blue;
        }
      }

      .view-only-unread {
        margin-left: 10px;
        align-items: center;
        .filter-unread-checkbox {
          background-color: $grey200 !important;
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }

    .notification-header-buttons {
      justify-content: space-between;
      .mark-all-read-btn {
        background-color: $mark-all-read-color;
        color: #fff;
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0px 15px;

        &:hover {
          transition: opacity 200ms ease-in-out;
          opacity: 0.8;
        }
        &--disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }

      .more-options-dots {
        background-color: #f2f2ff;
        padding: 10px;
        align-items: center;
        color: $mark-all-read-color;
        font-size: larger;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .close-modal {
    padding: 14px;
    height: fit-content;
    transform: translate(80%, -80%);
  }
}

.notification-body {
  $typeIndicatorSize: 5px;

  height: fit-content;
  max-height: 50vh;
  padding: 10px 2px;
  .notification-tab {
    cursor: pointer;
    padding: 10px;
    border: none;
    align-items: center;
    border-left: $typeIndicatorSize solid $quote;
    column-gap: 10px;
    background-color: $grey400;
    border-radius: 6px;
    font-size: 12px;
    min-height: 60px;

    &:hover {
      background-color: #e9f4fb;
    }
    // type control
    &--quote {
      border-left: $typeIndicatorSize solid $quote;
    }

    &--notice {
      border-left: $typeIndicatorSize solid $notice;
    }

    &--project {
      border-left: $typeIndicatorSize solid $project;
    }

    &--ticket {
      border-left: $typeIndicatorSize solid $ticket;
    }

    &--invoice {
      border-left: $typeIndicatorSize solid $invoice;
    }

    &--worker_invoice {
      border-left: $typeIndicatorSize solid $worker_invoice;
    }

    // If notification is unread

    .notification-title {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    &--unread {
      background-color: rgba($color: #ff0000, $alpha: 0.1);

      &.notification-tab--quote {
        background: rgba($quote, 0.1);
      }

      &.notification-tab--notice {
        background: rgba($notice, 0.1);
      }

      &.notification-tab--project {
        background: rgba($project, 0.1);
      }

      &.notification-tab--ticket {
        background: rgba($ticket, 0.1);
      }

      &.notification-tab--invoice {
        background: rgba($invoice, 0.1);
      }

      &.notification-tab--worker_invoice {
        background: rgba($color: $worker_invoice, $alpha: 0.1);
      }

      .notification-title {
        font-weight: bold;
      }
    }
    .time-frame {
      color: $grey100;
      font-style: italic;
    }

    .notification-button {
      background-color: $grey400;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: fit-content;
      color: #2c2929;
      border: 1px solid $grey100;
      cursor: pointer;
      padding: 6px 0;
      text-transform: unset;
      transition: all ease-in-out 200ms;
      font-size: 12px;

      &:hover {
        color: #000000;
        border: 1px solid #000000;
        background-color: white;
      }
    }
  }
}
