.top-fix-on-check {
  &:checked {
    + label {
      &::before {
        @apply block absolute left-0 top-2 border-1 border-gray-300 rounded-sm;
        content: '';
        height: 16px;
        width: 16px;
        top: -5px;
      }
    }
  }
}
