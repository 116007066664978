.project-list {
  &--to-price {
    background: lighten($color: #e2420d, $amount: 50%);
  }
  &--active {
    background: lighten($color: #3452c0, $amount: 50%);
  }

  &--completed {
    background: lighten($color: #76cc00, $amount: 55%);
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f3f4f6;
  }
}
