.arrow-block-end {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbfdfe;
  background-color: rgb(52, 100, 176);
  width: 20px;
}

.arrow-block-end:after {
  background-color: white;
  content: '';
  border-color: rgb(238, 245, 255) rgb(238, 245, 255) rgb(238, 245, 255)
    rgb(52, 100, 176);
  border-style: solid;
  border-width: 19px 0 19px 20px;
}

.arrow-block-begin {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbfdfe;
  background-color: rgb(238, 245, 255);
  width: 20px;
}

.arrow-block-begin:after {
  background-color: white;
  content: '';
  border-color: rgb(52, 100, 176) rgb(52, 100, 176) rgb(52, 100, 176)
    rgba(238, 245, 255, 0.7);
  border-style: solid;
  border-width: 19px 0 19px 20px;
}
