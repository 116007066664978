.c-checkbox {
  @apply relative;
  input {
    display: none;
    @apply absolute left-0 top-0 p-0 opacity-0;
    width: 0;
    height: 0;
  }

  label {
    &::before {
      @apply block absolute left-0 top-2 border-1 border-gray-300 rounded-sm;
      content: '';
      height: 16px;
      width: 16px;
      top: 2px;
    }
  }

  input:checked {
    + label {
      &::before {
        // top: -5px;
        @apply bg-blue-300 border-blue-300;
        background-image: url('../../assets/images/icon-check-white.svg');
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
      }
    }
  }
  input[type='radio'] {
    + label {
      &::before {
        @apply rounded-full;
      }
    }
    &:checked {
      + label {
        &::before {
          @apply rounded-full;
        }
      }
    }
  }
}
