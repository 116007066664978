.basic-project-details-tooltip {
  padding: 16px;
  width: max-content;
  min-width: 180px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  border: 1px solid white;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);

  font-weight: 700;
  letter-spacing: 0.8px;

  & > .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    & > .label-value {
      letter-spacing: 0.9px;
    }
  }

  & > .separator {
    background-color: white;
    height: 1px;
    width: 100%;
  }

  & > .description {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;

    font-size: 12px;
    font-weight: normal;

    & > .desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 12px;

      width: 100%;

      & > .desc-value {
        font-weight: 700;
        letter-spacing: 0.9px;
      }
    }
  }
}

.red-bar {
  color: #e2420d;
}

.green-bar {
  color: #76cc00;
}

.blue-bar {
  color: #3452c0;
}
