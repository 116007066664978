input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  user-select: none;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  user-select: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
// // .react-datepicker-wrapper {
// //   //   width: auto;
// //   height: 100%;
// // }
