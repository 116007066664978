body {
  font-size: 12px;

  & input {
    outline: none;
  }

  & textarea {
    resize: none !important;
  }
}

@import "../app/main";
@import "./styles.scss", "./tailwind.scss";
